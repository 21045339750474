@import "@alltrails/shared/styles/landsEnd.scss";
.breadcrumbWrapper {
  @include flex-row;
  align-items: center;
  padding: var(--space-150) 0;
  max-width: 100%;

  @media (max-width: $breakpoint-sm-size) {
    padding: var(--space-150) var(--space-200);
  }
}

.breadcrumbExploreLink {
  border-right: var(--borders-weight-sm) solid var(--color-neutral-200);
  padding-right: var(--space-100);
  @include text-100-bold;
  line-height: 1;

  @media (max-width: $breakpoint-sm-size) {
    &:first-child {
      display: none;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0 0 0 var(--space-100);
  max-width: 100%;

  li {
    a {
      text-decoration: none;
    }
  }

  &.truncateLastItem {
    li:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
